import pack4x65Pasteles from "../assets/products/pack4x65-pasteles.webp";
import pack4x65Vocales from "../assets/products/pack4x65-vocales.webp";
import pack4x65FigurasGeo from "../assets/products/pack4x65-figuras-geo.webp";
import pack4x125 from "../assets/products/pack4x125.webp";
import kitABC from "../assets/products/kit-abc.webp";
import primerasActividades from "../assets/products/primeras-actividades.webp";
import kitNumeros from "../assets/products/kit-numeros.webp";
import kitFiguras from "../assets/products/kit-figuras.webp";
import cajax24 from "../assets/products/cajax24.webp";

const productsInfoDetails = () => {
  // Emulate response server with the products
  const productsInfo = {
    products: [
      {
        name: "Plastimasa Kiwy - Pack4 x 65gr",
        price: "15.000",
        pastPrice: "17.000",
        description:
          "Incluye 4 tarros de plastilina ecológica KIWY x 65 gr. En colores: amarillo, azul, rojo y verde o rosado, azul pastel, verde biche y naranja. Cada color de la tapa corresponde al color de la plastilina, además, cada tapa incluye un molde en bajo relieve de animalitos del océano (delfín, pez, pulpo y estrella de mar.",
        shortDescription: "4 tarros de plastilina ecológica KIWY x 65 gr",
        image: pack4x65Pasteles,
        id: 1,
        stars: 5,
        specifications: [
          "Colores primarios + verde",
          "Colores pasteles + ácidos",
          "Colores pasteles + neón",
        ],
      },
      {
        name: "Plastimasa Kiwy - Pack 4 x 65gr + vocales",
        price: "20.000",
        pastPrice: "21.500",
        description:
          "Incluye 4 tarros de plastilina ecológica KIWY x 65 gr. En colores: amarillo, azul, rojo y verde. Cada color de la tapa corresponde al color de la plastilina, además, cada tapa incluye un molde en bajo relieve de animalitos del océano (delfín, pez, pulpo y estrella de mar)",
        shortDescription: "4 tarros de plastilina ecológica KIWY x 65 gr",
        image: pack4x65Vocales,
        id: 2,
        stars: 5,
        specifications: ["Moldes de las 5 vocales en minúscula"],
      },
      {
        name: "Plastimasa Kiwy - Pack 4 x 65gr + Figuras Geométricas",
        price: "20.000",
        pastPrice: "21.500",
        description:
          "Incluye 4 tarros de plastilina ecológica KIWY x 65 gr. En colores amarillo, azul, rojo y verde, cada color de la tapa corresponde al color de la plastilina, cada tapa incluye un molde en bajo relieve de animalitos del océano (delfín, pez, pulpo y estrella de mar)",
        shortDescription: "4 tarros de plastilina ecológica KIWY x 65 gr",
        image: pack4x65FigurasGeo,
        id: 3,
        stars: 5,
        specifications: [
          "Moldes de 5 figuras geométricas. (Circulo, cuadrado, Triangulo, rectángulo y Pentágono.)",
        ],
      },
      {
        name: "Plastimasa Kiwy - Pack 4 x 125gr",
        price: "20.000",
        pastPrice: "22.300",
        description:
          "Incluye 4 tarros de plastilina ecológica KIWY x 125 gr, viene en dos combinaciones de colores diferentes.",
        shortDescription: "4 tarros de plastilina ecológica KIWY x 125 gr",
        image: pack4x125,
        id: 4,
        stars: 5,
        specifications: [
          "Rosado, azul pastel, verde biche y naranja.",
          "Amarillo, azul, rojo y verde",
        ],
      },
      {
        name: "Plastimasa Kiwy - Kit ABC",
        price: "22.000",
        pastPrice: "24.500",
        description:
          "Incluye 2 tarros de plastilina ecológica KIWY x 65 gr, con molde en la tapa de animales marinos. ",
        shortDescription: "4 tarros de plastilina ecológica KIWY x 125 gr",
        image: kitABC,
        id: 5,
        stars: 5,
        specifications: [
          "La M es la misma W invertida y la N es la misma Z girada a 90°",
          "24 moldes de abecedario y un rodillo liso.",
        ],
      },
      {
        name: "Plastimasa Kiwy - Balde primeras actividades",
        price: "60.000",
        pastPrice: "65.000",
        description:
          "Incluye 4 tarros de plastilina ecológica KIWY x 65 gr. En colores amarillo, azul,rojo y verde, cada color de la tapa corresponde al color de la plastilina",
        shortDescription: "Balde para primeras actividades surtido",
        image: primerasActividades,
        id: 6,
        stars: 5,
        specifications: [
          "Moldes de 5 figuras geométricas. (Circulo, cuadrado, Triangulo, rectángulo y Pentágono.)",
          "9 moldes de Números del 0 al 8 ( el 9 es el mismo 6 invertido)",
          "Moldes de las 5 vocales en minúscula",
          "24 moldes de abecedario y un rodillo liso.",
          "La M es la misma W invertida y la N es la misma Z girada a 90°",
        ],
      },
      {
        name: "Plastimasa Kiwy - Kit números",
        price: "27.000",
        pastPrice: "29.000",
        description:
          "Incluye 4 tarros de plastilina Ecológica KIWY x 65 gr, con molde en la tapa de animales marinos",
        shortDescription:
          "Incluye 4 tarros de plastilina Ecológica KIWY x 65 gr",
        image: kitNumeros,
        id: 7,
        stars: 5,
        specifications: [
          "8 moldes de Números del 1 al 8 ( el 9 es el mismo 6 invertido)",
        ],
      },
      {
        name: "Plastimasa Kiwy - Kit Figuras",
        price: "25.000",
        pastPrice: "27.000",
        description:
          "Incluye 4 tarros de plastilina Ecológica KIWY x 65 gr, con molde en la tapa de animales marinos",
        shortDescription:
          "Incluye 4 tarros de plastilina Ecológica KIWY x 65 gr",
        image: kitFiguras,
        id: 8,
        stars: 5,
        specifications: ["5 moldes de diferentes formas y tamaños"],
      },
      {
        name: "Plastimasa Kiwy - Caja x 24",
        price: "77.000",
        pastPrice: "82.000",
        description:
          "24 Tarros de Plastimasa Kiwy x 65 gr. De colores surtidos(Amarillo, azul, rojo, verde, naranja, azul pastel, verde biche, rosado, blanco y negro). El color de la tapa corresponde al color de la plastilina",
        shortDescription: "Caja de 24 plastilinas surtido",
        image: cajax24,
        id: 9,
        stars: 5,
        specifications: [
          "Cada tapa incluye un molde en bajo relieve de animalitos del océano (Delfín, pez, pulpo y estrella de mar)",
        ],
      },
    ],
  };
  return productsInfo;
};

export default productsInfoDetails;
