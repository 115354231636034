import StarFull from "../assets/icons/StarFull";
import { useNavigate } from "react-router-dom";

const ProductCard = ({ product }) => {
  const navigate = useNavigate();

  const renderStars = (quantity) => {
    const stars = [];

    for (let i = 0; i < quantity; i++) {
      stars.push(
        <div key={i} className="mr-1">
          <StarFull />
        </div>
      );
    }

    return stars;
  };

  const handleRouteProduct = (id) => {
    navigate(`/product/${id}`);
  };

  return (
    <div
      onClick={() => handleRouteProduct(product.id)}
      className="border border-gray-100 rounded-2xl flex flex-col shadow-lg pb-2 cursor-pointer"
    >
      <div className="w-[100%] ">
        <img
          src={product.image}
          className="rounded-t-2xl"
          alt="This is the product to sale"
        />
      </div>

      <div className="px-3 mt-2 flex justify-between">
        <div className="flex ">{renderStars(product.stars)}</div>
        <div className="">
          {product.pastPrice && (
            <div className="tracking-wide line-through text-blue-600">
              ${product.pastPrice}
            </div>
          )}
          {product.price && (
            <div className="text-gray-600 tracking-wide">${product.price}</div>
          )}
        </div>
      </div>
      <div className="flex flex-col px-4 w-full">
        <h2 className="text-red-400 tracking-wider font-semibold mt-1">
          {product.name}
        </h2>
        <p className="mt-1 text-[14px] md:text-[12px] text-gray-800 text-justify font-extralight">
          {product.shortDescription}
        </p>
      </div>
    </div>
  );
};

export default ProductCard;
