const Footer = () => {
  return (
    <div className="w-full relative" id="footer">
      <div className="w-full bg-[#FF941B] h-40 md:h-20 flex justify-between px-2 items-center md:px-10">
        <div className=" text-white font-semibold tracking-wide text-[16px] md:text-[12px] grid grid-cols-1 md:grid-cols-2 gap-x-4">
          <p className="font-extrabold underline">Contacto: </p>
          <p>Tel: +57-3117868904</p>
          <p>Email: ventas@locomercio.com</p>
          <p>Armenia, Quindío - (Colombia)</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
