import React from "react";
import Header from "./components/Header";

import Footer from "./components/Footer";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"; // Importa Routes

import ContainerProducts from "./components/ContainerProducts";
import ProductInfo from "./components/ProductInfo";
import WhatsappButton from "./components/WhatsappButton";

function App() {
  return (
    <Router>
      <div className="relative max-h-screen h-screen">
        <Header />
        <Routes>
          <Route path="/" element={<ContainerProducts />} />
          <Route path="/product/:id" element={<ProductInfo />} />
        </Routes>
        <Footer />
        <WhatsappButton />
      </div>
    </Router>
  );
}
export default App;
