import React from "react";
import logoWhatsapp from "../assets/logo-whatsapp.png";
import { whatsappPhoneNumber } from "../helpers/constants";

const WhatsappButton = () => {
  const handleClick = () => {
    const whatsappLink = `https://api.whatsapp.com/send?phone=${whatsappPhoneNumber}`;
    window.open(whatsappLink, "_blank");
  };

  return (
    <div
      className="fixed z-50 bottom-2 md:bottom-5 right-3 md:right-10 h-16 w-16 md:h-20 md:w-20 cursor-pointer"
      onClick={handleClick}
    >
      <img src={logoWhatsapp} alt="whatsapp" />
    </div>
  );
};

export default WhatsappButton;
